<template>
  <li
    class="list-group-item"
    :data-latitude="lat"
    :data-longitude="lng"
    @mouseover="mouseOverResultItem"
  >
    <div class="d-flex">
      <div class="resultItemImage">
        <img
          :src="avatar"
          alt="result"
          class="img-thumbnail"
          width="40"
          height="40"
        >
      </div>
      <div class="resultItemName flex-grow-1 ps-1">
        <a href="#">{{ fullname }}</a> <br>
        <div class="resultItemRate text-warning">
          <small>
            <i
              v-for="(item, i) in rate"
              :key="{i}"
              class="fa fa-star"
            />
            <i
              v-if="rateHalfNumber"
              class="fa fa-star-half"
            />
          </small>
        </div>
      </div>
    </div>

    <div class="row resultItemInfo">
      <div class="col-sm-8 fw-bold">
        Travel Date
      </div>
      <div class="col-sm-4">
        {{ $props.item.item.departure_date }}
      </div>
    </div>
    <div class="row resultItemInfo">
      <div class="col-sm-8 fw-bold">
        Travelling From - To
      </div>
      <div class="col-sm-4">
        {{ $props.item.item.o_code }} - {{ $props.item.item.d_code }}
      </div>
    </div>
    <div class="row  resultItemInfo">
      <div class="col-sm-12 col-md-8  fw-bold">
        No of passengers who need companionship
      </div>
      <div class="col-sm-12  col-md-4">
        {{ $props.item.item.persons_travelling }}
      </div>
    </div>
    <div class="row resultItemInfo">
      <div class="col-sm-8  fw-bold">
        Member since
      </div>
      <div class="col-sm-4">
        {{ $props.item.item.member_since }}
      </div>
    </div>
  </li>
</template>

<script>
    import MarkerWithLabel from "@googlemaps/markerwithlabel";
    var pictureLabel;
    export default {
        name: "ResultItem",
        props: {item : {}},
        data : function() {
          return {
              rateHalfNumber: false
          }
        },
        computed : {
            fullname : function () {
                let item = this.$props.item.item;
                // let str = '';
                // if (item.first_name != null && item.first_name.length != 0)
                //     str += item.first_name;
                // if (item.middle_name != null && item.middle_name.length != 0)
                //     str += " " + item.middle_name;
                // if (item.last_name != null && item.last_name.length > 0)
                //     str += " " + item.last_name;
                // return str;
                return item.first_name;
            },
            rate : function () {

                let item = this.$props.item.item;
                if (item.ratings == null )
                    return 0;
                if (!Number.isInteger(item.ratings)) {
                  //eslint-disable-next-line
                    this.$data.rateHalfNumber = true;
                    // return Math.floor(rate);
                    return Math.floor(item.ratings);
                }
              return 0;
            },
            lat : function(){
                let item = this.$props.item.item;
                // console.log("item.latitude", item.latitude)
                return item.latitude;
            },
            lng : function(){
                let item = this.$props.item.item;
                // console.log("item.longitude", item.longitude)
                return item.longitude
            },
            avatar : function(){
              let item = this.$props.item.item;
              let src = "https://via.placeholder.com/40x40";
              if (item.image != null && item.image.length > 0) {
                src = "/uploads/profile_picture/" + item.image;
              }
              return src;
            },

        },
        mounted() {
            // console.log("test props items")
            // console.log(this.$props.item.item)
        },
        methods : {
            mouseOverResultItem : function () {
              if (window.mymap == undefined)
                return;
                let item = this.$props.item.item;
                var self = this;
                // console.log("lat resuli  item: ", item.latitude)    ;
                // console.log("lng  resuli  item: ", item.longitude)    ;
                if (item.latitude != null && item.longitude != null) {
                    var latLng = new  window.google.maps.LatLng(item.latitude, item.longitude);
                    window.mymap.setCenter(latLng);
                    pictureLabel = document.createElement("img");
                    pictureLabel.src = "/img/map/trav_03.png";
                    pictureLabel.setAttribute('width', 24);
                    pictureLabel.setAttribute('height', 24);
                    var marker1 = new MarkerWithLabel({
                        position: latLng,
                        draggable: false,
                        clickable: true,
                        map: window.mymap,
                        icon: {
                            url: '/img/map/trav_03.png',
                            labelOrigin: new window.google.maps.Point(0, 0)
                        },
                        labelContent: self.fullname,
                        labelAnchor: new  window.google.maps.Point(-21, 3),
                        labelClass: "bg-secondary text-white p-1",
                        labelStyle: { opacity: 0.75 },
                    });

                    // var marker2 = new MarkerWithLabel({
                    //     position: new  window.google.maps.LatLng(49.475, -123.84),
                    //     draggable: true,
                    //     map: map,
                    //     labelContent: "$395K ",
                    //     icon: {
                    //         url: '/images/trav_03.png',
                    //         labelOrigin: new google.maps.Point(0, 0)
                    //     },
                    //     labelClass: "labels", // the CSS class for the label
                    //     labelStyle: { opacity: 1.0 },
                    // });
                    let tmpContent = "";

                    tmpContent += "<address>";
                    tmpContent += " <strong><i class='fa fa-user text-primary'></i>" + self.fullname + "</strong><br>";
                    tmpContent += "<i class='fa fa-calendar  text-success'></i> member Since : " + item.member_since + "  <br /><hr class='my-1'/>";
                    tmpContent += "<i class='fa fa-map-marker text-danger'></i> " + item.city + "<br>";
                    tmpContent += item.state;

                    tmpContent += "</address>";
                    var iw1 = new  window.google.maps.InfoWindow({
                        content: tmpContent,
                    });

                    window.google.maps.event.addListener(marker1, "click", function () {
                        iw1.open(window.mymap, this);
                    });
                    // var iw2 = new  window.google.maps.InfoWindow({
                    //     content: "Another Home For Sale",
                    // });
                    // window.google.maps.event.addListener(marker2, "click", function (e) {
                    //     iw2.open(window.mymap, this);
                    // });
                    return;
                }
            }
        }
    }
</script>

<style scoped>

</style>