<template>
  <div id="map"></div>
</template>

<script>
// import { ref } from "vue";
// let MarkerWithLabel = require("@googlemaps/markerwithlabel");
import MarkerWithLabel from '@googlemaps/markerwithlabel';
// var pictureLabel;
let googleMapScript;

export default {
  name: 'MyGoogleMap',
  props: {
    center: { lat: Number, lng: Number },
    zoom: Number,
    mapType: String,
    disableUI: Boolean,
  },
  updated() {
    var props = this.$props;
    if (props.center.lat != null && props.center.lng != null) {
      var latLng = new window.google.maps.LatLng(props.center.lat, props.center.lng);
      window.mymap.setCenter(latLng);
      return;
    }
  },
  created() {
    // the map element in the templste

    const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
    // create the script element to load
    googleMapScript = document.createElement('script');
    googleMapScript.setAttribute(
      'src',
      `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`
    );
    document.head.appendChild(googleMapScript);
    /**
     * this function is called as soon as the map is initialized
     */
    var props = this.$props;
    window.initMap = () => {
      // console.log("lat : ", props.center.lat)    ;
      // console.log("lng : ", props.center.lng)    ;
      // var latLng = new  window.google.maps.LatLng(49.47805, -123.84716);
      var latLng = new window.google.maps.LatLng(props.center.lat, props.center.lng);

      var map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 12,
        center: latLng,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      });
      window.mymap = map;
      //
      // pictureLabel = document.createElement("img");
      // pictureLabel.src = "/images/trav_03.png";
      // pictureLabel.setAttribute('width', 24);
      // pictureLabel.setAttribute('height', 24);
      // var marker1 = new MarkerWithLabel({
      //     position: homeLatLng,
      //     draggable: false,
      //     clickable: true,
      //     map: map,
      //     icon: {
      //         url: '/images/trav_03.png',
      //         labelOrigin: new google.maps.Point(0, 0)
      //     },
      //     labelContent: "test",
      //     labelAnchor: new  window.google.maps.Point(-21, 3),
      //     labelClass: "labelPicture", // the CSS class for the label
      //     labelStyle: { opacity: 0.75 },
      // });
      //
      new MarkerWithLabel({
        position: latLng,
        draggable: true,
        map: map,
        labelContent: 'Original Location',
        labelAnchor: new window.google.maps.Point(-21, 3),
        labelClass: 'bg-primary text-white p-1',
        labelStyle: { opacity: 1.0 },
      });
      // let tmpContent = "";
      //
      // tmpContent += "<address>";
      // tmpContent += " <strong><i class='fa fa-user text-primary'></i>Full customer name</strong><br>";
      // tmpContent += "<i class='fa fa-calendar  text-info'></i> member Since : 1234'  <br /><hr />";
      // tmpContent += "<i class='fa fa-map-marker text-warning'></i> 1355 Market Street, Suite 900<br>";
      // tmpContent += "San Francisco, CA 94103<br>";
      // tmpContent += "<abbr title='Phone'>P:</abbr> (123) 456-7890";
      //
      // tmpContent += "</address>";
      // var iw1 = new  window.google.maps.InfoWindow({
      //     content: tmpContent,
      // });
      // var iw2 = new  window.google.maps.InfoWindow({
      //     content: "Another Home For Sale",
      // });
      // window.google.maps.event.addListener(marker1, "click", function (e) {
      //     iw1.open(map, this);
      // });
      // window.google.maps.event.addListener(marker2, "click", function (e) {
      //     iw2.open(map, this);
      // });
    };
  },
  unmounted() {
    // document.head.removeChild(googleMapScript);
    // delete window.mymap;
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  min-height: 100vh;
  background-color: azure;
}
.labels {
  color: #ea4335;
  background-color: white;
  font-family: Roboto, Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  width: 40px;
  padding: 2px;
  border: 1px solid #999;
  box-sizing: border-box;
  white-space: nowrap;
}
.labelPicture {
  width: 24px;
  height: 24px;
}
</style>
